#block-menu-block-1 {
  width: 100%;
  margin-bottom: calc(-3rem + 5px);
  margin-top: 2rem;
  
  @media (--break-min-small) {
    bottom: 0;
    margin: 0;
    position: absolute;
    width: auto;
  }

  .menu {
    list-style: none;
    margin: 0;
    padding: 0;

    > .leaf {
      color: var(--c-blue-50);
      display: block;
      margin: 0 2px;
      padding: 0 0 5px 0;
      
      @media (--break-min-small) {
        display: inline-block;
      }
      
      &.active {
        color: black;
        
        @media (--break-min-small) {
          background: white;
        }

        > a {
          background: white!important;
        }
      }
      
      > a {
        background: white;
        color: currentColor;
        display: block;
        font-family: var(--ff-heading);
        font-size: 1.125rem;
        padding: .5rem 1rem;
        text-decoration: none;
        text-transform: uppercase;
        transition: background .2s;
        
        @media (--break-min-small) {
          padding: 1rem;
        }

        &:hover {
          background: var(--c-green-5);
        }
      }
    }
  }
}