.view-display-id-testimonials {
  padding: 2.75rem 0;


  .view-content {

    @media (--break-min-tiny) {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -0.5625rem;
      width: calc(100% + 1.125rem);
    }

    .views-row {
      display: flex;
      flex-direction: column;
      background: white;
      margin: .5625rem 0 2rem;
      position: relative;
      width: 100%;

      @media (--break-min-tiny) {
        flex: 0 0 auto;
        margin: .5625rem;
        width: calc(50% - 1.125rem);
      }

    }

  }

}


.c-testimonial {
  /* margin: 2.75rem auto;
  max-width: 61.25rem; */

  &__content {
    padding: 2rem;
    width: 100%;
    
    @media (--break-min-small) {
      display: inline-flex;
      flex-flow: column nowrap;
      flex: 1 1 auto;
    }

    &-title {
      font-size: 2rem;
      margin-top: 0;
    }

    .field-name-field-body {
      flex: 0 1 auto;
      font-size: 1.0625rem;
      margin-bottom: 0.75rem;
      margin-top: auto;

      p {
        margin-bottom: 0;
      }
    }
  }

  .field-name-field-image {

    .field-item {
      position: relative;

      &:before {
        content: '';
        display: block;
        padding-bottom: 56%;
      }

      img {
        bottom: 0;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }
    }
  }
}

.view-target-audience-pages { 
  position: relative;

  &:before {
    background: var(--c-green-5);
    bottom: 0;
    content: '';
    position: absolute;
    margin-left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }

  .views-row-even {

    .c-testimonial {
      margin: 0 auto;
    }
  }

  .clearfix:after {
    display: none;
  }
}

