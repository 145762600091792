/**
 * set a custom default :focus style
 */
:focus {
    outline: none;
}

/* mouse */
[data-whatinput='mouse'] :focus {
  box-shadow: inset 0 0 0 black;
}

/* keyboard */
[data-whatinput='keyboard'] :focus {
  border: 1px solid color(var(--c-blue-50) alpha(48%));
  box-shadow: 0 4px 6px color(var(--c-blue-50) alpha(32%));
}

/* touch */
/* [data-whatinput='touch'] :focus {
  border: 1px solid color(var(--c-blue-50) alpha(48%));
  box-shadow: 0 4px 6px color(var(--c-blue-50) alpha(32%));
} */
