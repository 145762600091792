@font-face {
  font-family: 'hs-icons';
  src:  url('../fonts/hs-icons.eot?nr992k');
  src:  url('../fonts/hs-icons.eot?nr992k#iefix') format('embedded-opentype'),
    url('../fonts/hs-icons.ttf?nr992k') format('truetype'),
    url('../fonts/hs-icons.woff?nr992k') format('woff'),
    url('../fonts/hs-icons.svg?nr992k#hs-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="hs-icon-"], [class*=" hs-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hs-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hs-icon-agenda:before {
  content: "\e900";
}
.hs-icon-angle-down:before {
  content: "\e901";
}
.hs-icon-arrow-down:before {
  content: "\e902";
}
.hs-icon-check-circle:before {
  content: "\e903";
}
.hs-icon-check:before {
  content: "\e904";
}
.hs-icon-close:before {
  content: "\e905";
}
.hs-icon-pin:before {
  content: "\e906";
}
.hs-icon-play:before {
  content: "\e907";
}
.hs-icon-questionmark:before {
  content: "\e908";
}
.hs-icon-quotes:before {
  content: "\e909";
}
.hs-icon-graduate:before {
  content: "\e90a";
}


@define-mixin hs-icon {
  font-family: 'hs-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}