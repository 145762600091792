/* Table */
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: var(--space-small) 0 var(--space-medium);
  table-layout: fixed;
  width: 100%;
}
th,
td {
  empty-cells: show;
  padding: var(--space-small);
  text-align: left;
}
th {
  font-weight: bold;
}
