.c-discipline-list {
  margin: 4.875rem 0 5.6rem;

  &__title {
    margin: 0 0 1.5rem;
    padding: 0 .725rem;
    
    @media (--break-min-tiny) {
      padding: 0 1.125rem;
    }
  }
  
  &__body {
    display: block;
    list-style: none;
    padding: 0;
    
    @media (--break-min-tiny) {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -.75rem;
      width: calc(100% + 1.5rem);
    }
  }
  
  &__item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    color: var(--c-blue-50);
    line-height: 1.3;
    padding: 0.75rem;
    
    &:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }
    
    @media (--break-min-tiny) {
      flex: 0 0 auto;
      margin: 0 .75rem;
      padding: 0.75rem 1.125rem;
      width: calc(50% - 1.5rem);
      
      &:nth-child(-n+2) {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
    
    @media (--break-min-medium) {
      width: calc(33.33% - 1.5rem);
      
      &:nth-child(-n+3) {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
    
  }

  &__link {
    color: currentColor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}