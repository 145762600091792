.c-main {
  
  .node-type-target-audience-page &__content-upper {
    margin-top: 4.5rem;
    position: relative;
  }

  .path-root-aanbod &__content-upper {
    background-color: var(--c-green-5);

  }

  .node-type-target-audience-page &__content-middle,
  .node-type-text-page:not(.front) &__content-middle {
    margin: 3rem 0 0;
    
    .o-container {
      display: flex;
      flex-flow: row wrap;

      & > *:first-child:not(.block-bean-bundle-fact):not(.block-bean-bundle-quote) {
        margin-left: 0;
        width: 100%;
      }
    }

    .block-bean-bundle-filter-cta {
      flex: 1 1 auto;
      margin-top: 3rem;
      margin-bottom: 3rem;
      width: 100%;
      
      @media (--break-min-medium) {
        width: calc(66.66% - 1rem);
        margin-left: 1rem;
      }
    }

    .block-felix {
      width: 100%;
    }

    .block-bean:not(.block-bean-bundle-filter-cta) {
      flex: 1 1 auto;
      margin: -.5px 0;
      width: 100%;
      
      @media (--break-min-tiny) {
        margin: 0 -.5px 3rem;
        width: 50%;
      }
      
      @media (--break-min-medium) {
        width: 33.33%;
      }
    }
  }

  &__content-middle {
    #block-views-contextual-elements-body {
      margin-bottom: 4rem;
    }
  }

  .path-veelgestelde-vragen & .o-container {
    max-width: 61.25rem;
  }

  &__contextual {
    padding-top: 3rem;
    padding-bottom: 3rem;
    position: relative;

    .node-type-text-page &,
    .path-veelgestelde-vragen & {
      margin-bottom: 4rem;
    }

    @media (--break-min-small) {
      align-items: center;
      display: flex;
      position: relative;
      height: 20rem;
    }

    .path-aanbod & {
      display: block;
      padding-top: 5rem;
    }
    
    &:before {
      background: var(--c-green-40);
      bottom: 0;
      content: '';
      margin-left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100vw;
      z-index: -1;
    }

    .views-field-field-page-title,
    .field-name-title-field {
      max-width: 27rem;

      h1 {
        font-size: 2.75rem;
        margin: 0;
      }
    }

    .front & {
      display: none;
    }
  }
}