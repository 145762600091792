/* Heading */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--ff-heading);
  font-weight: 500;
  line-height: 1.2;
  margin: var(--space-small) 0 var(--space-medium);
  text-transform: uppercase;
}
h1 { 
  font-size: 4rem;
  line-height: 1.07;
}
h2 {
  font-size: 1.9rem;

  @media (--break-min-tiny) {
    font-size: 2.25rem;
  }
}
h3 { 
  font-size: 2rem;
}
h4 { 
  font-size: 1.6rem;
}
h5 { 
  font-size: 1.4rem;
}
h6 {
  font-size: 1.2rem;
}

/* Paragraph */
p {
  margin: var(--space-small) 0 var(--space-medium);
}

/* Hyperlink */
a {
  color: var(--c-blue-50);
  cursor: pointer;
  text-decoration: underline currentColor;
  text-decoration-skip: ink;
  text-decoration-skip-ink: auto;

  &:hover,
  &:active,
  &:focus {
    color: color(var(--c-blue-50) shade(40%));
    outline: none;
    text-decoration: none;
  }
}

/* Figure */
figure {
  margin: 0;
}

/* Image */
img {
  image-rendering: auto;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

/* Subscript & Superscript */
sup, sub {
  /* font-size: var(--font-size-tiny); */
}

/* Address */
address {
  font-style: normal;
}

/* Line */
hr {
  border: none;
  /* border-bottom: 1px solid var(--color-xlight); */
  height: 1px;
  margin: var(--space-large) 0;
}

/* List */
ul,
ol {
  margin: var(--space-small) 0 var(--space-medium);
}
ul {
  list-style-type: disc;
}
ol {
  list-style-type: decimal;
}

/* Small */
small {
  /* font-size: var(--font-size-small); */
}
