/* Margin spacing top */
.u-spacing-mt--collapse {
  margin-top: 0;
}
.u-spacing-mt--tiny {
  margin-top: var(--space-tiny);
}
.u-spacing-mt--small {
  margin-top: var(--space-small);
}
.u-spacing-mt--medium {
  margin-top: var(--space-medium);
}
.u-spacing-mt--large {
  margin-top: var(--space-large);
}
.u-spacing-mt--huge {
  margin-top: var(--space-huge);
}

/* Margin spacing bottom */
.u-spacing-mb--collapse {
  margin-bottom: 0;
}
.u-spacing-mb--tiny {
  margin-bottom: var(--space-tiny);
}
.u-spacing-mb--small {
  margin-bottom: var(--space-small);
}
.u-spacing-mb--medium {
  margin-bottom: var(--space-medium);
}
.u-spacing-mb--large {
  margin-bottom: var(--space-large);
}
.u-spacing-mb--huge {
  margin-bottom: var(--space-huge);
}
