.c-active-filter {
  align-items: center;
  border: 1px solid var(--c-blue-50);
  border-radius: 2.5rem;
  display: inline-flex;
  flex-flow: row nowrap;
  height: 2.5rem;
  line-height: 1;
  margin: 0.25rem;
  padding: .25rem .9rem .25rem 1.25rem;
  
  &__label {
    font-size: 1rem;
    font-weight: bold;
  }

  &__close {
    background: none;
    border: none;
    padding: 0;
    
    &:before {
      @mixin hs-icon;
      content: "\e905";
      font-size: .95rem;
      margin-left: 0.75em;
    }
  }

  &--link {
    background: none;
    border: none;
    color: var(--c-blue-50);
    font-size: 1rem;
    margin-left: 0.75rem;
    padding-left: 0;
    padding-right: 0;
    text-decoration: underline;

    &:hover {
      color: color(var(--c-blue-50) blackness(24%));
    }
  }
}