.c-offer-cta {
  position: relative;
  padding-bottom: 3rem;

  &:before {
    background: var(--c-green-5);
    bottom: 0;
    content: '';
    margin-left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    width: 100vw;
    z-index: -1;
  }

  &__button {
    @mixin button--blue;
    background: #4290FF;

    @media (--break-max-mini) {
      padding-bottom: .95rem;
      padding-top: .75rem;
      text-align: center;
    }
  }
  
  &__visual {
    margin-left: 50%;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
    height: auto;
    min-height: 53rem;
    max-height: 64rem;

    .front &:after {
      background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,.38) 100%);
      bottom: 0;
      content: '';
      height: 30rem;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 37%;
    }

    &-img {
      bottom: 0;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    &-link {
      font-size: 0;
      text-decoration: none;
      color: white;
      outline: none;
      z-index: 9999;
      display: block; 
      margin-top: 2rem;

      &:hover,
      &:focus {
        color: white;
      }

      @media (--break-min-small) {
        left: 50%;
        position: absolute;
        bottom: 60%;
      }

      &:after {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f04b";
        color: var(--c-blue-50);
        font-size: 2.5rem;
        background-color: white;
        border-radius: 100%;
        width: 4.7rem;
        height: 4.7rem;
        text-align: center;
        line-height: 1.9em;
        padding-left: .2em;
      }
    }

    &-inner {
      left: 0;
      position: absolute;
      right: 0;
      top: 4rem;
      z-index: 10;
      padding: 0 1.5rem;
      
      @media (--break-min-small) {
        top: calc(4rem + 8vw);
      }
    }
  }
  
  &__title {
    max-width: 37rem;
    
    @media (--break-max-mini) {
      font-size: 2.75rem;
    }
  }

  &__search {
    background: var(--c-blue-50);
    padding: 1.5em 1.125rem;
    margin: 2rem 0;

    @media (--break-min-small) {
      margin-top: 8rem;
    }
  }

  &__textual {
    margin-top: 3rem;
    position: relative;
    z-index: 2;

    @media (--break-min-medium) {
      align-items: stretch;
      display: flex;
      flex-flow: row nowrap;
    }

    @media (--break-min-medium) {
      &-left,
      &-right {
        align-items: flex-start;
        display: inline-flex;
        flex-flow: column nowrap;
  
        & > * {
          flex: 0 0 auto;
  
          &.c-offer-cta__button {
            margin-top: auto;
          }
        }
      }
    }

    &-inner {
      width: 100%;
    }

    &-left {
      background-color: var(--c-green-5);
      background-image: url('../images/cta-bg.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 2rem 1.75rem;
      
      @media (--break-min-small) {
        padding: 2.25rem 4rem 2.5rem 2rem;
      }
      
      @media (--break-min-medium) {
        flex: 1 1 auto;
        width: 49rem;
      }

      .c-offer-cta__textual-content {
        font-size: 1.25rem;
        font-family: var(--ff-secondary);
        font-weight: normal;
        width: 100%;

        p:last-child {
          margin-bottom: 3.5rem;
        }
      }
    }

    &-right {
      background-color: var(--c-blue-20);
      padding: 2rem 1.75rem;
      height: 100%;
      width: 100%;
      
      @media (--break-min-medium) {
        &:not(.c-offer-cta__textual-right--small) {
          flex: 1 1 auto;
          width: 26rem;
        }
      }

      .c-offer-cta__textual-content {
        font-size: 1rem;
        width: 100%;
        

        ul {
          list-style: none;
          margin: 0 0 2.75rem;
          padding: 0;
          
          li {
            display: block;
            line-height: 1.25;
            margin: 1.25rem 0;
            padding: 0 0 0 2.5rem;
            position: relative;

            &:before {
              @mixin hs-icon;
              content: "\e903";
              font-size: 1.5rem;
              left: 0;
              position: absolute;
              top: -.05em;
            }
          }
        }
      }
    }

    &-title {
      margin-bottom: 5.5rem;
      margin-top: 0;
      position: relative;
      width: 100%;

      &--small {
        margin-bottom: 2rem;
        font-size: 1.5rem;
      }

      &--heading {
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
        padding: 0 1.125rem;
      }
    }

  }

  .field-name-field-cards {
  
    > .field-items {
      & > .field-item:last-child {
        
        @media (--break-max-tiny) {
          width: 100%;
        }

      }
    }

  }
}