/* Root variables */
:root {

  /* Font family */
  --ff-default: 'Roboto', sans-serif;
  --ff-secondary: 'Roboto Condensed', sans-serif;
  --ff-heading: 'Oswald';

  /* Font size */
  --font-size-min-base: 14px;
  --font-size-max-base: 16px;
  --font-size-default: 1rem;


  /* Font weight */
  --font-weight-base: 400;
  --font-weight-default: 400;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-bold: 600;
  --font-weight-fat: 700;
  --font-weight-black: 800;

  /* Line height */
  --line-height-base: 1.75;
  --line-height-default: 1.75;
  --line-height-tiny: 1.25;
  --line-height-small: 1.5;
  --line-height-heading: 1.25;

  /* Color */
  --c-blue-50: #006AFF;
  --c-blue-20: #1EEEF0;
  
  --c-green-40: #13E5BD;
  --c-green-20: #c8dad3;
  --c-green-10: #DBF2E8;
  --c-green-5: #ECF8F3;
  
  --c-yellow-50: #D8C958;

  --c-black-99: #010101;
  --c-black-98: #1D1D1D;


  /* Space */
  --space-default: calc(var(--font-size-default) * var(--line-height-base));
  --space-tiny: calc(var(--space-default) * .25);
  --space-small: calc(var(--space-default) * .5);
  --space-medium: calc(var(--space-default) * 1);
  --space-large: calc(var(--space-default) * 1.5);
  --space-huge: calc(var(--space-default) * 3);

  /* Width */
  --width-default: 1200px;
  --width-tiny: 520px;
  --width-small: 768px;
  --width-medium: 992px;
  --width-large: 1200px;
  --width-huge: 1440px;

  /* Breakpoints */
  @custom-media --break-min-tiny (min-width: 520px);
  @custom-media --break-min-small (min-width: 768px);
  @custom-media --break-min-medium (min-width: 992px);
  @custom-media --break-min-large (min-width: 1200px);
  @custom-media --break-min-huge (min-width: 1440px);

  @custom-media --break-max-mini (max-width: 519px);
  @custom-media --break-max-tiny (max-width: 767px);
  @custom-media --break-max-small (max-width: 991px);
  @custom-media --break-max-medium (max-width: 1199px);
  @custom-media --break-max-large (max-width: 1439px);

  @custom-media --break-only-tiny (min-width: 520px) and (max-width: 767px);
  @custom-media --break-only-small (min-width: 768px) and (max-width: 991px);
  @custom-media --break-only-medium (min-width: 992px) and (max-width: 1199px);
  @custom-media --break-only-large (min-width: 1200px) and (max-width: 1439px);
}
