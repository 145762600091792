/* Document */
*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  font-size: responsive var(--font-size-min-base) var(--font-size-max-base);
  font-range: var(--width-small) var(--width-large);
  line-height: 1.616;
}

html,
body {
  /* color: var(--color-default); */
  font-family: var(--ff-default);
  font-weight: normal;
  text-rendering: optimizeLegibility;

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
  margin: 0;
  width: 100%;
}

/* HTML 5 */
figcaption,
figure,
main {
  display: block;
}

section:after {
  @mixin clearfix;
}

#admin-menu {
  box-shadow: none!important;
}