@define-mixin button--blue {
  align-items: center;
  background: var(--c-blue-50);
  border: none;
  color: white;
  display: inline-flex;
  writing-mode: horizontal-tb;
  min-height: 3rem;
  font-family: var(--ff-heading);
  font-size: 1.25rem;
  line-height: 1;
  padding: .5rem 1.25rem .7rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: background .2s;

  &:hover, &.disabled {
    background: color(var(--c-blue-50) blackness(16%));
    color: white;
  }
}