#block-menu-block-2 {
  position: static;

  .menu {
    list-style: none;
    margin: 0;
    padding: 0;

    & > .leaf {
      /* color: var(--c-blue-50); */
      display: inline-block;
      font-family: var(--ff-heading);
      font-size: 1rem;
      margin: 0 .5rem;
      padding: 0;
      text-transform: uppercase;

      @media (--break-min-large) {
        font-size: 1.125rem;
        margin: 0 .6rem;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      & > a {
        color: currentColor;
        line-height: 1;
        position: relative;
        text-decoration: none;
        transition: color .3s;

        &:after {
          background: currentColor;
          bottom: -.5rem;
          content: '';
          height: 3px;
          left: 0;
          position: absolute;
          right: 0;
          transform: scale(0);
          transition: transform .3s;
          width: 100%;
        }

        &:hover,
        &:focus,
        &.active-trail {
          color: black;

          &:after {
            transform: scale(1);
          }
        }
      }
    }
  }


  /**
  * Slicknav styling
  */
  > .slicknav_menu {
    background: none;
    font-size: 1rem;
    padding: 0;

    @media (--break-min-medium) {
      display: none;
    }

    /**
      * Hamburger
      */
    .slicknav_btn {
      align-items: center;
      background: none;
      display: inline-flex;
      float: none;
      padding: 0;

      & > * {
        flex: 0 0 auto;
        
        &:first-child {
          order: 3;
          margin-left: 0.5rem;
        }
      }
      
      .slicknav_menutxt {
        color: black;
        font-family: var(--ff-heading);
        font-weight: 500;
        font-size: 16px;
        line-height: .2;
        text-shadow: none;
      }
      
      .slicknav_icon {
        color: black;
        float: none;
        margin: 0;
        padding-top: 1px;
        position: relative;
        width: 15px;
  
        &-bar {
          background-color: currentColor;
          box-shadow: none;
          height: 2px;
          width: 15px;
          transition: transform .2s;
          transform-origin: center left;
          
          &:not(:first-child) {
            margin-top: 3px;
          }

          &:not(:first-child):not(:last-child) {
            transform-origin: center;
          }
        }
      }

      &.slicknav_open {
        .slicknav_icon-bar {
          &:first-child {
            transform: rotate(45deg);
          }
          
          &:last-child {
            transform: rotate(-45deg);
          }
          
          &:not(:first-child):not(:last-child) {
            transform: scale(0);
          }
        }
      }
    }

    /**
     * Dropdown
     */
    .slicknav_nav {
      background: white;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      transform: translateY(100%);
      transition: overflow 0s ease .2s;

      &:after {
        background: rgba(0, 0, 0, .77);
        bottom: 0;
        content: '';
        height: 100vh;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        transform: translateY(100%);
        transition: opacity .2s;
      }

      &.show-overflow {
        overflow: visible;
        
        &:after {
          opacity: 1;
        }
      }

      li {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 16px;
        margin: 0;
        padding: 0;
        
        &:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        > a {
          color: var(--c-blue-50);
          margin: 0;
          padding: .9rem 2rem 1.1rem;

          &:hover {
            background: var(--c-green-5);
            border-radius: 0;
          }

          &.active-trail {
            color: black;
            text-decoration: underline;
          }
        }
      }
    }
  }

  > .content {
    display: none;

    @media (--break-min-medium) {
      display: block;
    }
  }
}