.c-cardlist {
  list-style: none;
  padding: 0 0 2rem;
  margin: 0;
  
  @media (--break-min-tiny) {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -.5rem;
    width: calc(100% + 1rem);
  }
  

  &__wrapper {
    position: relative;
    
    @media (--break-min-medium) {
      padding: 0 0 4rem;
    }

    &:before {
      background: var(--c-green-5);
      bottom: 0;
      content: '';
      display: block;
      margin-left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100vw;
      z-index: -1;
    }
  }

  &__item {
    margin: .5rem 0;

    @media (--break-min-tiny) {
      flex: 0 0 auto;
      margin: .5rem;
      width: calc(50% - 1rem);
    }
    
    @media (--break-min-medium) {
      width: calc(33.33% - 1rem);
    }
  }

  
  &__filter {
    flex: 0 0 auto;
    margin-bottom: 2rem;
    padding: 1.25rem .5rem;
    position: relative;
    width: 100%;

    @media (--break-min-medium) {
      margin: 0 0 2rem;
      padding: 1.5rem 0 0;
    }

    &:before {
      background: white;
      bottom: 0;
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 100vw;

      @media (--break-min-medium) {
        display: none;
      }
    }

    &-body {
      background: white;
      height: 0;
      overflow: hidden;
    
      @media (--break-min-medium) {
        background: color(black alpha(-23%));
        height: auto;
        left: 0;
        overflow: unset;
        padding: 1.5em 1.125rem;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-100%);
      }
    }

    &-toggle {
      @mixin button--blue;
      font-family: var(--ff-default);
      font-weight: bold;
      position: relative;
      text-transform: none;
      width: 100%;
      z-index: 2;

      @media (--break-min-medium) {
        display: none;
      }

      &:after {
        @mixin hs-icon;
        content: "\e901";
        font-size: 1rem;
        position: absolute;
        right: 1.25rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.is-open {
      .c-cardlist__filter-body {
        z-index: 3;
        position: relative;
      }

      .c-cardlist__filter-toggle {
        background: white;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        color: var(--c-blue-50);
        z-index: 3;

        &:after {
          color: black;
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  &__active-filters {
    display: block;
    margin-top: 1.25rem;
    position: relative;
    width: 100%;
    z-index: 2;
    
    @media (--break-min-medium) {
      /* margin-bottom: 2rem; */
      margin-top: 0;
    }
  }




  .filter-is-open &:after {
    background: rgba(0,0,0,.77);
    bottom: 0;
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: 2;

    @media (--break-min-medium) {
      display: none;
    }
  }
}
/* 
.not-front #block-hs-filter-search-app {
  display: none;
} */