.block-bean-bundle-quote {
  border-bottom: 1px solid #D2D2D2;
  border-top: 1px solid #D2D2D2;
  padding: 1.25rem .75rem 1.5rem;
  

  @media (--break-min-tiny) {
    border: none;
    border-left: 1px solid #D2D2D2;
    border-right: 1px solid #D2D2D2;
    padding: .75rem 3.625rem 2rem 2.25rem;
  }

  .content,
  .bean-quote {
    height: 100%;
  }

  .bean-quote > .content {
    display: flex;
    flex-flow: column nowrap;

    & > * {
      flex: 0 0 auto;
    }

    .field-name-field-link {
      margin-top: auto;
    }
  }

  .field-name-title-field {
    &:before {
      @mixin hs-icon;
      content: "\e909";
      color: var(--c-green-40);
      display: block;
      font-size: 2rem;
      margin-bottom: 1rem;
      margin-left: -0.05em;
      
      @media (--break-min-tiny) {
        font-size: 3rem;
        margin-bottom: 3rem;
      }
    }
    
    h4 {
      color: var(--c-black-98);
      font-size: 1.5rem;
      margin: 0 0 .75rem;
      
      @media (--break-min-tiny) {
        font-size: 1.875rem;
      }
    }
  }

  .field-name-field-link {
    a {
      color: var(--c-blue-50);
      font-size: 1.125rem;
    }
  }

  body &:nth-of-type(even) {
    .field-name-title-field:before {
      color: var(--c-yellow-50);
    }
  }
}