.c-footer {
  &__upper {
  }

  &__privacy-statement {
    justify-self: flex-end;
    margin-left: auto;
    margin-right: 1rem;

    @media (--break-max-mini) {
      margin-top: 1rem;
    }

    a {
      color: #fff;
      font-weight: bold;
      padding: .25rem .5rem;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__bottom {
    background: var(--c-black-98);
    color: white;

    &-inner {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      min-height: 4.5rem;
      padding-bottom: 1rem;
      padding-top: 1.5rem;

      @media (--break-min-tiny) {
        padding-top: 1rem;
      }
    }

    .c-closure-wrapper {
      flex: 0 1 auto;
      font-family: var(--ff-heading);
      font-size: 1.0625rem;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      @media (--break-min-tiny) {
        text-align: left;
        width: auto;
      }

      span:first-child {
        color: var(--c-green-40);
      }
    }

    .c-social-items {
      flex: 0 1 auto;
      margin-right: auto;
      margin-top: 1rem;

      @media (--break-min-tiny) {
        margin-right: 0;
        margin-top: 0;
      }

      &__list {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      &__item {
        border-right: 1px solid rgba(255, 255, 255, .1);
        display: inline-block;
        padding: .25rem .5rem;

        &:last-child {
          border-right: none;
        }

        > a {
          color: var(--c-green-10);
          font-size: 1.25rem;
          padding: .25rem;
          text-decoration: none;
          transition: color .2s;

          &:hover {
            color: var(--c-green-40);
          }

          &.fa-instagram {
            padding-top: .3rem;
          }
        }
      }
    }
  }
}
