.c-location-cta {
  background-color: var(--c-blue-20);
  background-image: url('../images/cta-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.75rem 1.125rem 1.125rem;
  
  @media (--break-min-tiny) {
    padding: 2.25rem 2.125rem 2rem;
  }
  
  &__title {
    margin: 0 0 2rem;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    
    &-item {
      display: block;
      line-height: 1.25;
      margin: 1.25rem 0;
      padding: 0 0 0 2.5rem;
      position: relative;

      &:before {
        @mixin hs-icon;
        content: "\e903";
        font-size: 1.5rem;
        left: 0;
        position: absolute;
        top: -.05em;
      }
    }
  }

  &__form {
    margin-top: 2.25rem;
  }
}