/* Container */
.o-container {
  margin-left: auto;
  margin-right: auto;
  max-width: calc(1180px + 3rem);
  padding-left: .75rem;
  padding-right: .75rem;
  width: 100%;
  
  @media (--break-min-small) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
