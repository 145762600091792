.c-filter {
  @media (--break-min-small) {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }
  
  @media (--break-min-medium) {
    flex-flow: row nowrap;
  }

  &__info {
    color: black;
    transition: all .2s;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;

    &--visible {
      visibility: visible;
      opacity: 1;
    }

    &--error {
      font-weight: bold;
      color: red;
    }

    &-flyout {
      background-color: white;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, .25);
      font-size: 0.875rem;
      left: 0;
      position: absolute;
      padding: .87rem;
      right: 0;
      transform: translateY(-127%);
      z-index: 9999;
      display: flex;
      align-items: center;
      
      .icon {
        height: 1.2rem;
        width: 1.2rem;
        margin-right: .35rem;
      }

      &:after {
        background: white;
        bottom: 0px;
        content: '';
        height: .5rem;
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(50%) rotate(45deg);
        width: .5rem;
      }
    }
  }

  &__title {
    color: white;
    font-size: 1.2rem;
    margin: 0 0 1.5rem 0;
    width: 100%;

    @media (--break-min-medium) {
      margin: 0 .75rem 0 0;
      width: 42%;
    }
  }

  &__item {
    flex: 1 1 auto;
    height: 3.25rem;
    margin-bottom: 1.5rem;
    position: relative;
    width: 100%;

    &:before {
      background-image: linear-gradient(-90deg, #FFFFFF 50%, rgba(255,255,255,0.00) 100%);
      bottom: 0;
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 4.25rem;
    }

    @media (--break-min-small) {
      margin: 0 .3125rem;
      width: 21.25rem;

      &:first-child {
        margin-left: 0;
      }
    }

    &:after {
      @mixin hs-icon;
      color: var(--c-blue-50);
      content: "\e901";
      pointer-events: none;
      position: absolute;
      right: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
    }

    &--no-arrow {
      &:after,
      &:before {
        display: none;
      }
    }
  }

  &__separator {
    display: none;

    @media (--break-min-small) {
      color: white;
      display: inline-block;
      flex: 0 0 auto;
      font-family: var(--ff-heading);
      font-size: 1.0625rem;
      margin: 0 .75rem;
      text-transform: uppercase;
    }
  }

  &__offer {
    appearance: none;
    border: none;
    border-radius: 0;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 2;
    height: 3.25rem;
    padding: 0 1.25rem;

    &:disabled {
      opacity: 1;
      filter: none;
      font-weight: normal;
      color: color(black alpha(50%));
    }
  }

  &__input {
    appearance: none;
    border: none!important;
    height: 3.25rem;

    &::placeholder {
      font-style: italic!important;
    }
  }

  &__action {
    flex: 0 0 auto;
    margin-top: 1.5rem;
    width: 100%;
    
    @media (--break-min-tiny) {
      height: 3.25rem;
    }
    
    @media (--break-min-medium) {
      margin-left: .3125rem;
      margin-top: 0;
      width: auto;
    }
    
    & > * {
      @mixin button--blue;
      justify-content: center;
      text-align: center;
      width: 100%;

      @media (--break-max-mini) {
        padding-bottom: 1rem;
        padding-top: 1rem;
      }

      @media (--break-min-tiny) {
        height: 100%;
      }

      @media (--break-min-medium) {
        width: auto;
      }
    }
  }

  &--cta {
    .c-filter {
      &__item {
        @media(--break-min-small) {
          margin: 0;
        }
      }

      &__action {
        @media (--break-min-medium) {
          margin-left: 1rem;
        }
      }
    }
  }

  &--location {
    .c-filter__action > * {
      text-align: center;
      
      @media (--break-min-medium) {
        width: 8.125rem;
      }
    }
  }

  &--list {
    @media (--break-max-small) {
      .c-filter__item {
        margin: 0;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        
        & > * {
          background: none;
          border: none;
          padding: 0 1.25rem;
        }
      }
    }
    
    .c-filter__action {
      @media (--break-max-small) {
        background: white;
        height: unset;
        margin: 0;
        padding: 1.25rem 1rem;

        button {
          height: 3.25rem;
        }
      }
    }
  }
}