.c-checklist {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
      display: block;
      line-height: 1.25;
      margin: 20px 0;
      margin: 1.25rem 0;
      padding: 0 0 0 40px;
      padding: 0 0 0 2.5rem;
      position: relative;
  }

  li:before {
    font-family: 'hs-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
        -moz-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e903";
    font-size: 24px;
    font-size: 1.5rem;
    left: 0;
    position: absolute;
    top: -.05em;
  }

}