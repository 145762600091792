/* Background image fit */
.u-bg-fit {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  & img {
    visibility: hidden;
  }
}
.u-bg-fit--cover {
  background-repeat: no-repeat;
  background-size: cover;
}
.u-bg-fit--contain {
  background-repeat: no-repeat;
  background-size: contain;
}

/* Float */
.u-float--left {
  float: left;
}
.u-float--right {
  float: right;
}

/* Shadow */
.u-shadow {
  box-shadow: 20px 20px 40px rgba(0, 0, 0, .25);
}

/* Clear fix */
.u-clearfix {
  @mixin clearfix;
}

/* No scroll */
.u-no-scroll {
  height: 100% !important;
  overflow: hidden !important;
}

/* Meta */
.u-meta {
  margin-top: -1rem;
  margin-bottom: 1rem;
}