
/**
 * Roboto
 */

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/hinted-Roboto-Regular.eot');
    src: url('../fonts/hinted-Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/hinted-Roboto-Regular.woff2') format('woff2'),
        url('../fonts/hinted-Roboto-Regular.woff') format('woff'),
        url('../fonts/hinted-Roboto-Regular.ttf') format('truetype'),
        url('../fonts/hinted-Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/hinted-Roboto-Bold.eot');
    src: url('../fonts/hinted-Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/hinted-Roboto-Bold.woff2') format('woff2'),
        url('../fonts/hinted-Roboto-Bold.woff') format('woff'),
        url('../fonts/hinted-Roboto-Bold.ttf') format('truetype'),
        url('../fonts/hinted-Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/hinted-Roboto-Italic.eot');
    src: url('../fonts/hinted-Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/hinted-Roboto-Italic.woff2') format('woff2'),
        url('../fonts/hinted-Roboto-Italic.woff') format('woff'),
        url('../fonts/hinted-Roboto-Italic.ttf') format('truetype'),
        url('../fonts/hinted-Roboto-Italic.svg#Roboto-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

/**
 * Oswald
 */

@font-face {
    font-family: 'Oswald';
    src: url('../fonts/hinted-Oswald-Medium.eot');
    src: url('../fonts/hinted-Oswald-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/hinted-Oswald-Medium.woff2') format('woff2'),
        url('../fonts/hinted-Oswald-Medium.woff') format('woff'),
        url('../fonts/hinted-Oswald-Medium.ttf') format('truetype'),
        url('../fonts/hinted-Oswald-Medium.svg#Oswald-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}


/**
 * Roboto Condensed
 */


@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/hinted-RobotoCondensed-Regular.eot');
    src: url('../fonts/hinted-RobotoCondensed-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/hinted-RobotoCondensed-Regular.woff2') format('woff2'),
        url('../fonts/hinted-RobotoCondensed-Regular.woff') format('woff'),
        url('../fonts/hinted-RobotoCondensed-Regular.ttf') format('truetype'),
        url('../fonts/hinted-RobotoCondensed-Regular.svg#RobotoCondensed-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/hinted-RobotoCondensed-Bold.eot');
    src: url('../fonts/hinted-RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/hinted-RobotoCondensed-Bold.woff2') format('woff2'),
        url('../fonts/hinted-RobotoCondensed-Bold.woff') format('woff'),
        url('../fonts/hinted-RobotoCondensed-Bold.ttf') format('truetype'),
        url('../fonts/hinted-RobotoCondensed-Bold.svg#RobotoCondensed-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}