.bean-experience-cards {
  position: relative;
  padding: 4rem 0 3.75rem;

  &:before {
    background: var(--c-green-5);
    bottom: 0;
    content: '';
    margin-left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    width: 100vw;
    z-index: -1;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 1.375rem;
    padding: 0 1rem;
  }

  .field-collection-container {
    border: none;
  }

  .action-links-field-collection-add {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      @mixin button--blue;
      border-radius: 2px;
      font-size: 1rem;
      margin-top: 1rem;
      min-height: 2.5rem;
    }
  }
}


.field-name-field-cards {
  
  .field-collection-view {
    border: none;
    margin: 0;
    padding: 0;
  }

  > .field-items {

    @media (--break-min-tiny) {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -0.5625rem;
      width: calc(100% + 1.125rem);
    }
    
    @media (--break-min-small) {
      flex-flow: row nowrap;
    }
    
    & > .field-item {
      background: white;
      margin: .5625rem 0;
      position: relative;
      width: 100%;
      
      @media (--break-min-tiny) {
        flex: 0 0 auto;
        margin: .5625rem;
        width: calc(50% - 1.125rem);
      }
      
      @media (--break-min-small) {
        width: calc(33.33% - 1.125rem);
      }

      .group-visual {
        position: relative;
        overflow: hidden;

        &:before {
          content: '';
          display: block;
          padding-bottom: 72%;
        }

        > .field {
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;

          &.field-name-field-video-embed {
            font-size: 0;
            overflow: hidden;
            z-index: 20;

            a {
              bottom: 0;
              color: white;
              left: 0;
              position: absolute;
              right: 0;
              text-decoration: none;
              top: 0;

              &:after {
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\f04b";
                color: var(--c-blue-50);
                font-size: 1.5rem;
                background-color: white;
                border-radius: 100%;
                width: 2.5rem;
                height: 2.5rem;
                text-align: center;
                line-height: 1.7em;
                padding-left: .2em;
                left:  1rem;
                position: absolute;
                bottom: 20px;
              }

              /* &:hover {
                color: var(--c-blue-50);
              } */
            }
          }

          &.field-name-field-image img { 
            bottom: 0;
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
          }
        }
      }

      .group-content {
        padding: 1.25rem 1rem 2rem;
        position: relative;
        min-height: 230px;
        display: flex;
        flex-direction: column;

        .field-name-title-field h5 {
          font-size: 1.5rem;
          line-height: 1.3;
          margin: 0 0 .75rem;
        }

        .field-name-field-link {
          margin-top: auto;

          a {
            @mixin button--blue;
            max-width: 100%;
            display: block;
          }
          
        }

      }
    }
  }
}