.block-bean-bundle-faq-block:not(#block-bean-veelgestelde-vragen-blok-home) {
  padding: 2.75rem 0;
  position: relative;

  &:before {
    background: var(--c-green-5);
    bottom: 0;
    content: '';
    position: absolute;
    margin-left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }

  .bean-faq-block {
    > .content {
      margin: 0 auto;
      max-width: 61.25rem;

      .more-link {
        margin-top: 1.5rem;
        text-align: left;

        > a {
          @mixin button--blue;
        }
      }

      .view-faqs > .view-content {
        .views-row {
          border-bottom: 1px solid rgba(0,0,0,0.2);

          &:first-child {
            border-top: 1px solid rgba(0,0,0,0.2);
          }

          .views-field-title {
            color: var(--c-blue-50);
            cursor: pointer;
            font-size: 1.0625rem;
            padding: 0.75rem;
            position: relative;
            transition: background .2s;

            &:after {
              @mixin hs-icon;
              content: "\e901";
              font-size: 0.9em;
              position: absolute;
              right: 1.5rem;
              top: 50%;
              transform: translateY(-50%);
              transition: transform .2s;
            }

            &:hover {
              background: color(var(--c-green-10) alpha(64%));
            }
          }

          .views-field-field-body {
            font-size: 1.125rem;
            height: 0;
            overflow: hidden;
            padding: 0 .75rem;

            .field-content {
              max-width: 42rem;
            }

            &:before {
              content: '';
              display: block;
              height: 1.25rem;
            }

            &:after {
              content: '';
              display: block;
              height: .75rem;
            }
          }

          &.is-open {
            .views-field-title {
              font-weight: bold;

              &:after {
                transform: translateY(-50%) rotate(-180deg);
              }

              &:hover {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
}
.block-bean-bundle-faq-block#block-bean-veelgestelde-vragen-blok-home {
  padding: 2.75rem 0;
  position: relative;

  &:before {
    background: var(--c-green-5);
    bottom: 0;
    content: '';
    position: absolute;
    margin-left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }

  .bean-faq-block {
    > .content {

      h2 {
        margin-top: 0;
        margin-bottom: 1.375rem;
        padding: 0 1rem;
      }

      .u-meta {
        padding: 0 1rem;
      }

      .view-faqs > .view-content {
        display: flex;
        flex-wrap: wrap;
        margin: 2rem 0;

        .views-row {
          border-bottom: 1px solid rgba(0,0,0,0.2);
          padding: 1.25rem .75rem 1.5rem;
          display: flex;
          flex-direction: column;

          @media (--break-min-tiny) {
            border-bottom: none;
            border-left: 1px solid rgba(0,0,0,0.2);
            margin: 0.75rem 0;
            padding: 0.75rem 2.5rem;
            flex: 0 0 auto;
            width: 50%;
          }

          @media (--break-min-medium) {
            width: 33.33%;
          }

          > a {
            margin-top: auto;
          }

        }

        .views-field-title {
          font-family: var(--ff-heading);
          font-weight: 500;
          line-height: 1.3;
          font-size: 1.5rem;
          margin-bottom: 2.5rem;
          text-transform: uppercase;

          @media (--break-min-small) {
            font-size: 2rem;
          }
        }

      }

    }
  }

}