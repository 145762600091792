.block-bean-bundle-text-enumeration-block {
  position: relative;
  padding: 4rem 0 3.75rem;

  h2 {
    margin-top: 0;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }

  .field-name-field-text-list {
    .field-item {
      display: block;
      line-height: 1.25;
      margin: 1.25rem 0;
      padding: 0 0 0 2.5rem;
      position: relative;
      font-size: 1.25rem;

      &:before {
        @mixin hs-icon;
        content: "\e903";
        font-size: 1.5rem;
        left: 0;
        position: absolute;
        top: .1em;
      }
    }
  }
}