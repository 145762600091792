.c-header {
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.09);
  position: relative;
  z-index: 100;

  &__inner {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 1rem;
    padding-top: 1rem;

    & > * {
      flex: 0 1 auto;

      &.block-menu-block {
        margin-left: auto;
      }

      &.c-logo__wrapper .c-logo {
        @media (--break-max-tiny) {
          height: 2rem;
        }

        &__link {
          display: inline-block;
        }
      }
    }
  }
}