.c-cookie-bar{
  background: var(--c-blue-20);
  bottom: 0;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, .2);
  color: black;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  transform: translate(0, 100%);
  transition: all .3s ease-in-out;
  z-index: 99;
  &.show{
    opacity: 1;
    pointer-events: all;
    transform: none;
  }
  &__inner{
    padding: var(--space-medium) 1.5rem;
    position: relative;

    @media (--break-min-small) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    a{
      color: inherit;
    }
  }
  &__heading{
    width: 100%;
    font-size: 25px;
    line-height: 1.2;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  &__text {
    width: 100%;
  }

  &__explanation{
    margin-bottom: 15px;
    @media (--break-min-small) {
      padding-right: var(--space-huge);
    }
  }
  &__button{

  }
}