.block-bean-bundle-image {
  margin: 0 auto;
  max-width: 61.25rem;

  .field-name-field-visual {
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-bottom: 56%;
    }

    img {
      bottom: 0;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
}