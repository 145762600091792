.c-tabs {
  .o-container {
    position: relative;

    .tabs {
      border-bottom: none;
      position: absolute;
      margin: 0;
      right: 0;
      top: 1rem;
      z-index: 99;

      li {
        display: inline-block;

        a {
          @mixin button--blue;
          border-radius: 2px;
          font-size: 1rem;
          min-height: 2.5rem;
        }

        &.active a {
          background: white;
          border: 1px solid rgba(0,0,0,0.08);
          color: var(--c-blue-50);
        }
      }
    }
  }
}