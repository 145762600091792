.block-bean-bundle-video {
  margin: 0 auto;
  max-width: 61.25rem;

  .embedded-video {
    .player {
      position: relative;

      &:before {
        content: '';
        display: block;
        padding-bottom: 55%;
      }

      iframe {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }
    }
  }
}