/* Hide utilities */
.u-hide {
  display: none !important;
}

/* Hide @only */
.u-hide\@tiny {

  @media (--break-only-tiny) {
    display: none !important;
  }

}
.u-hide\@small {

  @media (--break-only-small) {
    display: none !important;
  }

}
.u-hide\@medium {

  @media (--break-only-medium) {
    display: none !important;
  }

}
.u-hide\@large {

  @media (--break-only-large) {
    display: none !important;
  }

}
.u-hide\@huge {

  @media (--break-min-huge) {
    display: none !important;
  }

}

/* Hide @min */
.u-hide\@min-tiny {

  @media (--break-min-tiny) {
    display: none !important;
  }

}
.u-hide\@min-small {

  @media (--break-min-small) {
    display: none !important;
  }

}
.u-hide\@min-medium {

  @media (--break-min-medium) {
    display: none !important;
  }

}
.u-hide\@min-large {

  @media (--break-min-large) {
    display: none !important;
  }

}
.u-hide\@min-huge {

  @media (--break-min-huge) {
    display: none !important;
  }

}

/* Hide @max */
.u-hide\@max-tiny {

  @media (--break-max-tiny) {
    display: none !important;
  }

}
.u-hide\@max-small {

  @media (--break-max-small) {
    display: none !important;
  }

}
.u-hide\@max-medium {

  @media (--break-max-medium) {
    display: none !important;
  }

}
.u-hide\@max-large {

  @media (--break-max-large) {
    display: none !important;
  }

}

/* Show utilities */
.u-show {
  display: block !important;
}

/* Show only */
.u-show\@tiny {
  display: none;

  @media (--break-only-tiny) {
    display: block !important;
  }

}
.u-show\@small {
  display: none;

  @media (--break-only-small) {
    display: block !important;
  }

}
.u-show\@medium {
  display: none;

  @media (--break-only-medium) {
    display: block !important;
  }

}
.u-show\@large {
  display: none;

  @media (--break-only-large) {
    display: block !important;
  }

}
.u-show\@huge {
  display: none;

  @media (--break-min-huge) {
    display: block !important;
  }

}
