.c-card {
  background: white;
  height: 100%;
  padding: 1.25rem 1rem 4.5rem;
  position: relative;
  transition: opacity .3s;
  
  @media (--break-min-small) {
    padding: 1.5rem 1.375rem 5rem;
    display: flex;
    flex-direction: column;
  }

  &__submit {
    @mixin button--blue;
    bottom: 1.5rem;
    font-size: 1.125rem;
    left: 1rem;
    position: absolute;
    justify-content: center;
    right: 1rem;
    
    @media (--break-min-small) {
      bottom: 2rem;
      left: 1.375rem;
      right: 1.375rem;
    }
  }

  &__logo {
    margin-bottom: 1rem;
    height: 5rem;
    position: relative;
    width: 100%;

    &-link {
      @media (--break-max-tiny) {
        pointer-events: none;
      }
    }

    &-img {
      bottom: .5rem;
      height: calc(100% - 1rem);
      left: 2rem;
      object-fit: contain;
      opacity: 0.5;
      position: absolute;
      right: 2rem;
      top: .5rem;
      width: calc(100% - 4rem);
    }
  }

  &__title {
    margin: 0 0 1rem;
  }

  &__trainings {
    list-style: none;
    margin: 1rem 0;
    padding: 0;
    
    @media (--break-min-small) {
      align-items: stretch;
      display: flex;
      flex-flow: row wrap;
      margin: 1rem -2.5px;
      width: calc(100% + 5px);
    }

    &-item {
      align-items: center;
      background: var(--c-green-5);
      border-bottom: 2px solid var(--c-green-20);
      color: black;
      display: flex;
      font-weight: bold;
      height: 2.5rem;
      line-height: 1;
      margin: 5px 0;
      padding: .75rem;
      text-decoration: none;
      
      @media (--break-min-small) {
        display: inline-flex;
        flex: 0 0 auto;
        font-size: 0.85rem;
        margin: 2.5px;
        padding: 0.75rem;
      }

      &:after {
        @mixin hs-icon;
        color: var(--c-blue-50);
        content: "\e904";
        font-size: 0.9em;
        margin-left: auto;
        
        @media (--break-min-small) {
          margin-left: 0.25rem;
        }
      }
    }

    ~ .c-card__link {
      @media (--break-min-small) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__link {
    opacity: 0.8;
    line-height: 1;

    &--secondary {
      color: #000000;
      font-size: 0.9rem; 

      &:hover {
        color: #000000;
      }

      /* &:after {
        content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
        margin: 0 3px 0 5px;
      } */

    }
  }

  &__data {
    list-style: none;
    margin: 2rem 0 1.5rem;
    padding: 0;

    @media (--break-min-small) {
      font-size: 0.9375rem;
      margin: 2.5rem 0;
      margin-top: auto;
    }

    &-item:not(:first-child) {
      border-bottom: 1px solid rgba(0,0,0,0.16);
    }

    &-item:only-child {
      border-top: 1px solid rgba(0,0,0,0.16);
    }

    &-item:not(.has-link),
    &-link {
      align-items: center;
      display: flex;
      line-height: 1;
      padding: 0.9375rem .75rem 0.9375rem 0.6rem ;
      
      @media (--break-min-small) {
        padding: 0.9375rem 1.25rem 0.9375rem 0.9375rem ;
      }
      
      &:before {
        @mixin hs-icon;
        display: none;
        font-size: 1.5em;
        
        @media (--break-min-small) {
          display: inline;
          margin-right: .5em;
          width: 2rem;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &-item.has-link {
      transition: opacity .3s;
      opacity: 1;

      &:hover {
        opacity: .9;
      }
    }

    &-link {
      color: black;
      cursor: pointer;
      text-decoration: underline;
    }
  }


  &__open-day {
    background: var(--c-green-10);
    border-top: none;
    border-bottom: 2px solid var(--c-green-20);

    strong {
      flex: 0 0 auto;
      margin-left: auto;
    }

    .c-card__data-link {
      text-decoration: none;
    }

    .c-card__data-link:before {
      content: "\e900";
    }
  }

  &__test-studying {
    .c-card__data-link:before {
      content: "\e90a";
      font-size: 1.75em;
      overflow: hidden;
    }
  }

  &__contact {
    align-items: center;
    display: flex;
    flex-direction: row;

    &:before {
      content: "\e906";
    }

    &-separator {
      margin: 0 .2rem;
    }
  }

  &--loading {
    opacity: .6;
    overflow: hidden;
    position: relative;

    &:after {
      animation: animate-skeleton 1.5s infinite forwards;
      filter: blur(32px);
      background: white;
      bottom: -8rem;
      content: '';
      opacity: 0.6;
      position: absolute;
      width: 2.5rem;
      top: -8rem;
      transform: translateX(-100%) rotate(-15deg);
    }
  }


  &--skeleton {
    opacity: 1;

    span {
      display: inline-block;
      background: rgba(29,29,29,0.10);
      border-radius: 2px;
    }

    .c-card__logo > span {
      height: 3rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 10rem;
    }

    .c-card__title > span {
      height: 1.25rem;
      width: 80%;
    }

    .c-card__trainings-item {
      min-width: 25%;

      &:after {
        display: none;
      }
    }

    .c-card__link {
      background: var(--c-blue-50);
      height: 1.25rem;
      opacity: 0.4;
      width: 60%;
    }

    .c-card__data-item:before {
      opacity: 0;
    }

    .c-card__submit {
      background: var(--c-blue-50);
      opacity: 0.4;
    }
  }
}

@keyframes animate-skeleton {
  0% {
    left: -50%;
  }

  100% {
    left: 150%;
  }
}