/* Settings */
@import 'settings/setting.fonts.css';
@import 'settings/setting.global.css';

/* Tools */
@import 'tools/mixin.aspect-ratio.css';
@import 'tools/mixin.buttons.css';
@import 'tools/mixin.clearfix.css';
@import 'tools/mixin.triangle.css';
@import 'tools/tool.font-awesome.css';
@import 'tools/tool.hs-icon.css';
@import 'tools/tool.modaal.css';
@import 'tools/tool.normalize.css';
@import 'tools/tool.slicknav.css';
@import 'tools/tool.what-input.css';

/* Elements */
@import 'elements/element.page.css';
@import 'elements/element.type.css';
@import 'elements/element.form.css';
@import 'elements/element.table.css';

/* Objects */
@import 'objects/object.container.css';
@import 'objects/object.layout.css';

/* Components */
@import 'components/component.active-filter.css';
@import 'components/component.button.css';
@import 'components/component.card.css';
@import 'components/component.cardlist.css';
@import 'components/component.cards.css';
@import 'components/component.discipline-list.css';
@import 'components/component.faq.css';
@import 'components/component.fact.css';
@import 'components/component.felix.css';
@import 'components/component.filter.css';
@import 'components/component.footer.css';
@import 'components/component.header.css';
@import 'components/component.image.css';
@import 'components/component.location-cta.css';
@import 'components/component.main.css';
@import 'components/component.menu.css';
@import 'components/component.offer-cta.css';
@import 'components/component.quote.css';
@import 'components/component.sub-menu.css';
@import 'components/component.tabs.css';
@import 'components/component.testimonial.css';
@import 'components/component.universities.css';
@import 'components/component.video.css';
@import 'components/component.visual-small.css';
@import 'components/component.active-filter.css';
@import 'components/component.header-image.css';
@import 'components/component.text-enumeration.css';
@import 'components/component.title-block.css';
@import 'components/component.icon.css';
@import 'components/component.cookie-bar.css';
@import 'components/component.checklist.css';


/* Trumps */
@import 'trumps/trump.utilities.css';
@import 'trumps/trump.text.css';
@import 'trumps/trump.display.css';
@import 'trumps/trump.spacing.css';

