.block-bean-bundle-fact {
  border-bottom: 1px solid #D2D2D2;
  border-top: 1px solid #D2D2D2;
  padding: 1.25rem 0.75rem 1.5rem;
  
  @media (--break-min-tiny) {
    border: none;
    border-left: 1px solid #D2D2D2;
    border-right: 1px solid #D2D2D2;
    padding: .75rem 3.625rem 4rem 2.25rem;
  }

  .field-name-field-body {
    &:before {
      @mixin hs-icon;
      content: "\e908";
      color: var(--c-green-40);
      display: block;
      font-size: 2rem;
      margin-bottom: 1rem;
      margin-left: -0.25em;
      
      @media (--break-min-tiny) {
        font-size: 3rem;
        margin-bottom: 3rem;
      }
    }
    
    p {
      font-family: var(--ff-secondary);
      font-size: 1.125rem;
      line-height: 1.33;
      margin: 0;
      
      @media (--break-min-tiny) {
        font-size: 1.45rem;
      }
    }
  }
}