.view-universities > .view-content {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -.5rem;
  width: calc(100% + 1rem);

  .views-row {
    flex: 0 1 auto;
    position: relative;
    margin: 0.5rem;
    width: 20%;

    @media (--break-min-small) {
      flex: 0 0 auto;
      width: 8rem;
    }

    .views-field > .field-content {
      position: relative;

      &:before {
        content: '';
        display: block;
        padding-bottom: 40%;
      }

      img {
        bottom: 0;
        /* filter: grayscale(100%); */
        height: 100%;
        left: 0;
        object-fit: contain;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }

    }
    
    .link > a {
      bottom: 0;
      display: block;
      font-size: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
}

#block-views-universities-logos {
  margin-bottom: 3rem;
  margin-top: 2.75rem;
}