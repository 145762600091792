.block-felix {
  background: color(var(--c-blue-20) alpha(15%));
  border: 1px dashed var(--c-blue-50);
  border-radius: 2px;
  padding: 2rem 2rem 2.5rem;
  margin: 2rem 0;
  outline: none!important;

  h2 {
    color: var(--c-blue-50);
    font-size: 1.75rem;
    margin: 0;
  }

  .contextual-links-trigger {
    display: none!important;
  }

  .contextual-links-wrapper {
    z-index: 90;

    .contextual-links {
      background: none!important;
      border: none!important;
      display: block!important;
      padding: 0 !important;
      right: 1.5rem;
      top: 2rem;
      
      > li > a {
        @mixin button--blue;
        color: white!important;
        border-radius: 2px;
        font-size: 1rem;
        margin: 0 !important;
        min-height: 2.5rem;
      }
    }
  }
}