/* Triangle top */
@define-mixin triangle-top $width: 10px, $height: 10px, $color: var(--color-primary) {
  border-color: transparent transparent $color transparent;
  border-style: solid;
  border-width: 0 calc($width / 2) $height calc($width / 2);
  width: 0;
  height: 0;
}

/* Triangle bottom */
@define-mixin triangle-bottom $width: 10px, $height: 10px, $color: var(--color-primary) {
  border-color: $color transparent transparent transparent;
  border-style: solid;
  border-width: $height calc($width / 2) 0 calc($width / 2);
  width: 0;
  height: 0;
}

/* Triangle left */
@define-mixin triangle-left $width: 10px, $height: 10px, $color: var(--color-primary) {
  border-color: transparent $color transparent transparent;
  border-style: solid;
  border-width: calc($height / 2) $width calc($height / 2) 0;
  width: 0;
  height: 0;
}

/* Triangle right */
@define-mixin triangle-right $width: 10px, $height: 10px, $color: var(--color-primary) {
  border-color: transparent transparent transparent $color;
  border-style: solid;
  border-width: calc($height / 2) 0 calc($height / 2) $width;
  width: 0;
  height: 0;
}

/* Triangle top right */
@define-mixin triangle-top-right $width: 10px, $height: 10px, $color: var(--color-primary) {
  border-color: transparent $color transparent transparent;
  border-style: solid;
  border-width: 0 $width $height 0;
  width: 0;
  height: 0;
}

/* Triangle bottom right */
@define-mixin triangle-bottom-right $width: 10px, $height: 10px, $color: var(--color-primary) {
  border-color: transparent transparent $color transparent;
  border-style: solid;
  border-width: 0 0 $height $width;
  width: 0;
  height: 0;
}

/* Triangle bottom left */
@define-mixin triangle-bottom-left $width: 10px, $height: 10px, $color: var(--color-primary) {
  border-color: transparent transparent transparent $color;
  border-style: solid;
  border-width: $height 0 0 $width;
  width: 0;
  height: 0;
}

/* Triangle top left */
@define-mixin triangle-top-left $width: 10px, $height: 10px, $color: var(--color-primary) {
  border-color: $color transparent transparent transparent;
  border-style: solid;
  border-width: $height $width 0 0;
  width: 0;
  height: 0;
}
